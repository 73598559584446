import { LOGIN_STATUS } from "./interfaces"

export default (setUserState, firebaseAuth, alert) => {
    firebaseAuth.signOut(firebaseAuth.getAuth()).then(attempt => {
        alert.success('Logout successful')
        setUserState({
        user: null,
        loginStatus: LOGIN_STATUS.LOGGING_OUT
        })
    }).catch(error => {
        alert.success('Logout error occurred')
    })
}
