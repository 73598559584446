import Dialog from "@mui/material/Dialog"
import { useUser, LOGIN_STATUS } from "../../contexts/UserContext/UserContext"
import CircularProgress from "@mui/material/CircularProgress"

function Spinner() {
    const {userState} = useUser()

    return (<Dialog
        open={userState.loginStatus===LOGIN_STATUS.LOADING || userState.loginStatus===LOGIN_STATUS.LOGGING_IN}
        PaperProps={{
            elevation: 0,
            sx: {
                bgcolor: 'transparent',
                overflow: "hidden"
            }
        }}
    >
        <CircularProgress size={100} sx={{color: theme => theme.palette.secondary.light}} />
    </Dialog>)
}

export default Spinner