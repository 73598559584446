import { ListItemButton as MUIListItemButton } from "@mui/material"
import { styled } from "@mui/material/styles"

function ListItemButton(props) {
    const Button = styled(MUIListItemButton)(() => ({
        minHeight: 72
    }))

    return <Button {...props} />
}

export default ListItemButton