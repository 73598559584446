import { useDirectory } from "../../../contexts/DirectoryContext"
import DirectoryUI from "./DirectoryUI"

function Directory() {
    const { directoryState } = useDirectory()

    const formatBytes = val => {
        const conversion = Math.log2(val)/10
        return Math.round(conversion*10)/10 + ['b','KB','MB','GB'][Math.floor(conversion)]
    }
    
    const files = directoryState.map(dir => {
        const bytes = formatBytes(dir.pdfDoc.metadata.size)
        const dt = new Date(dir.pdfDoc.metadata.uploadDate)
        const uploaded = dt.toLocaleDateString('en-CA')

        return ({
            primary: dir.pdfDoc.filename,
            secondary: `${bytes} | Uploaded: ${uploaded}`
        })
    })

    return(<>
        {
            files.map((text, index) => <DirectoryUI
                key={index}
                index={index}
                text={text}
            />)
        }
    </>)
}

export default Directory