import { LOGIN_STATUS } from "./interfaces"

const logInFailureByCode = (code, error) => {
  switch(code) {
    case "auth/invalid-email":
      error('Login error encountered: invalid email')
    break

    case "auth/invalid-credential":
      error('Login error encountered: invalid password')
    break

    default:
      error('Login error encountered')
    break
  }
}

export default (data, setUserState, firebaseAuth, alert) => {
  const auth = firebaseAuth.getAuth()
  const email = data.get('email').toString()
  const password = data.get('password').toString()

  firebaseAuth.signInWithEmailAndPassword(auth, email, password).then(attempt => {
    alert.success('Successfully logged in')

    setUserState({
      user: attempt.user,
      loginStatus: LOGIN_STATUS.LOGGING_IN
    })

  }).catch(error => {
    logInFailureByCode(error.code, alert.error)
  })
}