import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { useDirectory } from "../../contexts/DirectoryContext"
import { useFile } from "../../contexts/FileContext"

function DrawerPlaceholder() {
    const { directoryState, open } = useDirectory()
    const { uploadRef } = useFile()
    const shouldDisplay = directoryState.length===0 && open

    return (<Box
        sx={{
            display: shouldDisplay ? "flex" : "none",
            alignItems: 'center',
            flexDirection: 'column',
            margin: 'auto'
        }}
        onClick={() => uploadRef.current?.click()}
    >
        <br />
        <br />
        <TextSnippetIcon color="secondary" fontSize="large"/>
        <br />
        <Typography
            variant="h6"
            sx={{
                margin: 'auto',
                fontWeight: 'bold',
                color: theme => theme.palette.secondary.main,
                textAlign: 'center'
            }}
        >
            Your files will appear <br /> here on upload
        </Typography>
    </Box>)
}

export default DrawerPlaceholder