import { createContext, useContext, useEffect, useState } from 'react'
import AzureDBService, { DB_COLLECTIONS, UserDBService } from '../services/AzureDBService'
import StorageService from "../services/StorageService"
import { useFirebase } from './FirebaseContext'
import { LOGIN_STATUS, useUser } from './UserContext/UserContext'
import { directoryContentsInterface } from './interfaces'
import { useAlert } from './AlertContext'

const DirectoryContext = createContext(null)
DirectoryContext.displayName = 'DirectoryContext'

interface directoryContextValuesInterface {
    open: boolean,
    setOpen: (shouldBeOpen: boolean) => void,
    ifOpen: (a: any, b?: any) => any,

    chatSendEnabled: boolean,
    setChatSendEnabled: (shouldBeOpen: boolean) => void,

    directoryState: Array<directoryContentsInterface>,
    setDirectoryState: (dir: Array<directoryContentsInterface>) => void,

    deleteFile: (index: number) => Promise<void>,
    loadDirectory: (id: string) => Promise<void>
}

const DirectoryProvider = ({ children }) => {
    const firebase = useFirebase()
    const { userState, confirmLogin, confirmLogout } = useUser()
    const alert = useAlert()

    const [ open, setOpen ] = useState(false)
    const [ chatSendEnabled, setChatSendEnabled ] = useState(false)
    const [ directoryState, setDirectoryState ] = useState([])

    const ss = new StorageService(firebase)
    const pdfDb = new AzureDBService(firebase, DB_COLLECTIONS.PDF)
    const chatDb = new AzureDBService(firebase, DB_COLLECTIONS.CHAT)
    const userDb = new UserDBService(firebase)

    const loadDirectory = async (id? : string) => {
        setChatSendEnabled(false)

        const pdfSnapshots = await pdfDb.query({userId: userDb.getUID()})

        if(pdfSnapshots.statusCode===404) {
            setDirectoryState([])
            return
        }

        const fileArray = []

        pdfSnapshots.forEach(snap => fileArray.push({
            pdfDoc: snap,
            id: snap._id,
            selected: false
        }))

        if(fileArray.length > 0) {
            const index = id
                ?   fileArray.findIndex(file => file.id===id)
                :   0
                
            fileArray[index].selected = true
        }

        setDirectoryState(fileArray)

        return
    }

    useEffect(() => {
        ;(async () => {
            if(userState.loginStatus===LOGIN_STATUS.LOGGING_OUT) {
                setDirectoryState([])
                confirmLogout()
                return
            }

            if(userState.loginStatus===LOGIN_STATUS.LOGGING_IN) {

                await loadDirectory()
                confirmLogin()
            }
        })()
    }, [userState.loginStatus])

    const ifOpen = (a,b) => b === undefined
        ? open && a
        : open ? a : b

    const deleteFile = async (index) => {
        setChatSendEnabled(false)

        const id = directoryState[index].id

        await pdfDb.delete(id)

        const pdfAiRef = directoryState[index]?.pdfDoc?.pdfAiRef
        if(pdfAiRef) {
            const chatObj = await chatDb.query({ pdfAiRef })
            await chatDb.delete(chatObj[0]._id)
        }
        
        ss.deletePdfAzure(pdfAiRef)

        await loadDirectory()
        alert.success('PDF successfully deleted')
    }
    
    const directoryContextValues : directoryContextValuesInterface = {
        open,
        setOpen,
        chatSendEnabled,
        setChatSendEnabled,
        ifOpen,
        directoryState,
        setDirectoryState,
        deleteFile,
        loadDirectory
    }

    return (<DirectoryContext.Provider value={directoryContextValues}>
        {children}
    </DirectoryContext.Provider>)
}

const useDirectory = () : directoryContextValuesInterface => useContext(DirectoryContext)

export {
    DirectoryProvider,
    DirectoryContext,
    useDirectory
}