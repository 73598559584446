import { Paper, Typography } from "@mui/material"

function ExamplePrompt({children}) {
    return (<Paper elevation={0} sx={{
        borderColor: theme => theme.palette.primary.main,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        margin: theme => theme.spacing(1)
    }}>
        <Typography 
            sx={{
                padding: theme => theme.spacing(1),
                fontSize: '12px'
            }}
        >
            {children}
        </Typography>
    </Paper>)
}

export default ExamplePrompt