import Paper from "@mui/material/Paper"
import { FILE_STATUS, useFile } from "../../contexts/FileContext"
import { useEffect, useRef, useState } from "react"
import ChatService, { CHAT_DIRECTION, chatInterface } from "../../services/ChatService"
import { useFirebase } from "../../contexts/FirebaseContext"
import { useAlert } from "../../contexts/AlertContext"
import ChatBox from "./ChatBox"
import PlaceholderChatbox from "./PlaceholderChatbox"
import { useDirectory } from "../../contexts/DirectoryContext"

function ChatBoxContainer() {
    const alert = useAlert()
    
    const { setChatSendEnabled } = useDirectory()
    const { fileState } = useFile()
    const { fileStatus, pdfDoc: { pdfAiRef } } = fileState
    
    const cs = new ChatService(useFirebase())
    
    const [chats, setChats] = useState([])

    // TODO this stopped working
    const msgListRef = useRef(null)
    const scrollToBottom = () => {
        msgListRef.current?.scrollToBottom("smooth")
    }

    const addChat = (
        message: any,
        direction: CHAT_DIRECTION,
        pdfAiRef: string,
        references?: any
    ): void => {
        const newChat: chatInterface = {
            message,
            direction,
            position: "normal",
            type: "html"
        }
        if(references) newChat.references = references

        setChatSendEnabled(direction===CHAT_DIRECTION.INCOMING)
        setChats([ ...chats, newChat ])

       scrollToBottom()
        cs.saveChatFB(newChat, pdfAiRef)
    }
    const customizeResponse = content => {
        if(content.startsWith("I'm sorry")) {
            return `I'm sorry, I cannot provide an answer. There may be a few reasons for this:\n1. The question is not relevant to the scope of the content of the reference file.\n2. The file is unreadable because it is a low-quality scanned document.\n\nPlease contact support if this issue persists.`
        }
        return content
    }

    useEffect(() => {
        // is it the AI's turn to respond?
        if(!chats.length) return
        
        const latestChat = chats[chats.length - 1]

        if(latestChat.direction!=='outgoing') return

        cs.chatEP(pdfAiRef, latestChat.message)
        .then(response => {
            addChat(
                customizeResponse(response.content),
                CHAT_DIRECTION.INCOMING,
                pdfAiRef,
                response.references
            )
        })
    }, [chats])

    useEffect(() => {
        const shouldClearChats = [
            FILE_STATUS.CLEAR,
            FILE_STATUS.OTHER
        ].some(status => status===fileStatus)

        if(shouldClearChats) setChats([])
        if(fileStatus!==FILE_STATUS.LOADED) return

        // ANALYZING
        ;(async function() {
            try {
                const chats = await cs.fetchChatsFB(pdfAiRef)

                setChats(chats)
                scrollToBottom()
                setChatSendEnabled(true)
            } catch(err) {
                console.error(err)
                alert.error('Error analyzing pdf')
            }
        })()
    }, [fileStatus])

    const ask = message => addChat(message, CHAT_DIRECTION.OUTGOING, pdfAiRef)

    return (<Paper
        sx={{
            height: '90vh',
            width: '90%',
            position: "relative",
            paddingTop: theme => theme.spacing(4),
            borderRadius: '0'
        }}
    >
        <ChatBox
            chats={chats}
            ask={ask}
            msgListRef={msgListRef}
        />

        <PlaceholderChatbox />
    </Paper>)
}

export default ChatBoxContainer
