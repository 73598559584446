import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import Dropzone from "react-dropzone"
import { useTheme } from "@mui/material"
import { useFile } from "../../../contexts/FileContext"
import { checkEnv } from "../../../util/env"

const USER_HOVER_STYLES = true

function CustomDropzoneUI({setFiles}) {
    const theme = useTheme()
    const { uploadRef } = useFile()

    const hoverStyles = () => {
        if(!USER_HOVER_STYLES) return {}
    
        return {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.dark,
                border: `2px dashed ${theme.palette.primary.main}`
            },
            '&:hover .dropzone-text': {
                color: theme.palette.primary.light,
            },
            '&:hover .dropzone-logo': {
                color: theme.palette.primary.light,
            }
        }
    }

    const labelNotesSx = {
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        fontSize: '12px'
    }

    return (<Dropzone onDrop = { acceptedFiles => {
        setFiles(acceptedFiles)
    }}>
        {({getRootProps, getInputProps}) => (<Box
            {...getRootProps()}
            sx={{
                backgroundColor: theme.palette.primary.light,
                border: `2px dashed ${theme.palette.primary.main}`,
                borderRadius: theme.spacing(2),
                padding: theme.spacing(4, 0),
                margin: 'auto',
                display: "flex",
                flexDirection: "column",
                minWidth: '67%',
                ...hoverStyles()
            }}
        >
            <input {...getInputProps()} ref={uploadRef} />
            <Typography className="dropzone-text" variant="h6" sx={{
                margin: 'auto',
                fontWeight: 'bold!important',
                color: theme.palette.primary.contrastText
            }}>
                Upload a Document to Start
            </Typography>
            <Typography className="dropzone-text" sx={{ ...labelNotesSx, fontSize: '12px' }}>
                Drag and drop a document and analyze it with Ultimarii
            </Typography>
            <br/>
            <FileUploadOutlinedIcon
                className="dropzone-logo"
                sx={{
                    color: theme.palette.secondary.main,
                    margin: 'auto'
                }}
                fontSize="large"
            />
            
            <Box sx={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}>
                <Typography className="dropzone-text" sx={labelNotesSx}>
                    Supported types: PDF{checkEnv.DOCX_UPLOAD_SUPPORT("enabled", ', DOCX')}
                </Typography>
                <Typography className="dropzone-text" sx={labelNotesSx}>
                    Max file size per file: 100MB
                </Typography>
            </Box>
        </Box>)}
    </Dropzone>)
}

export default CustomDropzoneUI