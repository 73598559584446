import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Container } from '@mui/material'
import Background from './views/Background'
import { AlertProvider } from './contexts/AlertContext'
import { UserProvider } from './contexts/UserContext/UserContext'
import { FirebaseProvider } from './contexts/FirebaseContext'
import { FileProvider } from './contexts/FileContext'
import { DirectoryProvider } from './contexts/DirectoryContext'
import { FeedbackProvider } from './contexts/FeedbackContext'

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#0198CB',
      light: '#EEFAFF',
      dark: '#315B6A',
      contrastText: '#677E91'
    },
    secondary: {
      main: '#84B5C5', // green-ish
      light: '#F7FCFD', // super light
    }
  },
  typography: {
    fontFamily: `"Noto Sans", sans-serif`,
  }
})

const containerStyles = {
  padding: '0 !important',
  background: 'linear-gradient(90deg, #ffffff 0%, #ffffff00 100%),linear-gradient(180deg, #f2f6e6 0%, #fff 50%, #f2fbfe 100%)',
  height: '100vh',
  width: '100vw',
  position: 'absolute'
}

function App() {
  return (<Router>
    <ThemeProvider theme={baseTheme}>
      <Container sx={containerStyles} maxWidth={false} //ironically makes the container maxWidth
      >
        <FirebaseProvider>
          <AlertProvider>
            <UserProvider>
              <DirectoryProvider>
                <FileProvider>
                  <FeedbackProvider>
                    <Routes>
                      <Route path ='/' element={<Background />} />
                    </Routes>
                  </FeedbackProvider>
                </FileProvider>
              </DirectoryProvider>
            </UserProvider>
          </AlertProvider>
        </FirebaseProvider>
      </Container>
    </ThemeProvider>
  </Router>)
}

export default App
