import { ListItem, IconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import DeleteIcon from '@mui/icons-material/Delete'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { FILE_STATUS, useFile } from "../../../contexts/FileContext"
import { useDirectory } from "../../../contexts/DirectoryContext"

function DirectoryUI({text, index}) {
    const {
        directoryState,
        setDirectoryState,
        ifOpen,
        chatSendEnabled
    } = useDirectory()
    const { deleteFile } = useDirectory()
    const { fileState, clear } = useFile()

    const deleteThisFile = index => ifOpen(<IconButton aria-label="delete" onClick={() => {
            deleteFile(index)
        }}>
            <DeleteIcon />
        </IconButton>)

    const canSwitchFiles = [FILE_STATUS.LOADED, FILE_STATUS.CLEAR]
        .some(status => status===fileState.fileStatus)

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
      ) => {
        if(canSwitchFiles) {
            clear(FILE_STATUS.OTHER)
            setDirectoryState(directoryState.map((content, i) => {
                content.selected = index===i
                return content
            }, []))
        }
    }

    const title = <Typography component="h1">{text.primary}</Typography>
    const selected = directoryState[index]?.selected ?? false

    return(<Tooltip title={title} placement="right" arrow>
        <ListItem key={index} secondaryAction={deleteThisFile(index)} disablePadding>
            <ListItemButton
                sx={{
                    '&.Mui-selected': {
                        backgroundColor: theme => theme.palette.primary.light,
                    }
                }}
                selected={selected}
                onClick={event => handleListItemClick(event, index)}
            >
                <ListItemIcon sx={{ mr: ifOpen(-1,'auto'), ml: 0.5}}>
                    {(selected && !chatSendEnabled)
                        ?   <CircularProgress size={24} />
                        :   <TextSnippetIcon />}
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{ style: {
                        whiteSpace: ifOpen("normal","no-wrap"),
                        overflowX: 'clip'
                    }}}
                    primary={text.primary}
                    secondary={text.secondary}
                    sx={{ opacity: ifOpen(1,0) }}
                />
            </ListItemButton>
        </ListItem>
    </Tooltip>)
}

export default DirectoryUI