import { createContext, useContext, useEffect, useRef, useState } from 'react'
import StorageService from '../services/StorageService'
import { useFirebase } from './FirebaseContext'
import { useDirectory } from './DirectoryContext'
import { FILE_STATUS, fileStateInterface, pdfDocInterface, referencesInterface } from './interfaces'
import ChatService from '../services/ChatService'

const FileContext = createContext(null)
FileContext.displayName = 'FileContext'

interface fileContextValuesInterface {
    fileState : fileStateInterface,
    clear: (status ?: FILE_STATUS) => void,
    beginUpload : () => void,
    uploadRef: any,
    referenceState: referencesInterface,
    setReferenceState: (ref : referencesInterface) => void
}

const getInitialFileState = (fileStatus = FILE_STATUS.CLEAR)
: fileStateInterface => ({
    pdfDoc: {
        filename: '',
        pdfAiRef: '',
        storageRef: '',
        extension: '',
        mimetype: ''
    },
    id: '',
    fileLocalUrl: '',
    fileStatus
})

const FileProvider = ({ children }) => {
    const firebase = useFirebase()
    const { directoryState } = useDirectory()
    const uploadRef = useRef(null)

    const ss = new StorageService(firebase)
    const cs = new ChatService(firebase)

    const [ fileState, setFileState ] = useState(
        getInitialFileState(FILE_STATUS.OTHER)
    )

    const [ referenceState, setReferenceState ] = useState({
        pageNumber: -1,
        fromLine: -1
    })

    const loadFile = async (pdfDoc: pdfDocInterface, id: string) => {
        const fileLocalUrl = await ss.createLocalUrl(
            pdfDoc.storageRef,
            pdfDoc.filename,
            pdfDoc.extension
        )

        setFileState({
            pdfDoc,
            id,
            fileLocalUrl,
            fileStatus: FILE_STATUS.ANALYZING
        })

        pdfDoc.pdfAiRef = await cs.analyze(id)

        setFileState({
            ...fileState,
            pdfDoc,
            fileStatus: FILE_STATUS.LOADED
        })
    }

    const beginUpload = () => {
        setFileState(getInitialFileState(FILE_STATUS.UPLOADING))
    }

    const clear = (state: FILE_STATUS = FILE_STATUS.CLEAR) =>
        setFileState(getInitialFileState(state))

    useEffect(() => {
        clear(FILE_STATUS.OTHER)
        if(directoryState.length===0) return clear()

        const selectedFile = directoryState.find(file => file.selected)
        if(!selectedFile) return clear()

        loadFile(selectedFile.pdfDoc, selectedFile.id)
    }, [directoryState])

    const fileContextValues : fileContextValuesInterface = {
        fileState,
        clear,
        beginUpload,
        uploadRef,
        referenceState,
        setReferenceState
    }

    return (
        <FileContext.Provider value={fileContextValues}>
        {children}
    </FileContext.Provider>
    )
}

const useFile = () : fileContextValuesInterface => useContext(FileContext)
  
export {
    FileProvider,
    FileContext,
    FILE_STATUS,
    useFile
}