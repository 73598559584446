import { createContext, useContext } from 'react'
import app from 'firebase/compat/app'
import * as auth from 'firebase/auth'
import env from '../util/env'

const FirebaseContext = createContext(null)
FirebaseContext.displayName = 'FirebaseContext'

interface firebaseContextInterface { 
  app,
  auth
}

const FirebaseProvider = ({ children }) => {

    const config = {
        appId: env.REACT_APP_APP_ID,
        apiKey: env.REACT_APP_API_KEY,
        authDomain: env.REACT_APP_AUTH_DOMAIN,
        projectId: env.REACT_APP_PROJECT_ID,
        storageBucket: env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
      }
      const firebaseApp = app.initializeApp(config)

      const firebaseContextValues : firebaseContextInterface = {
        app,
        auth
      }
      
      return (
        <FirebaseContext.Provider value={firebaseContextValues}>
          {children}
        </FirebaseContext.Provider>
      )
}

const useFirebase = () => useContext(FirebaseContext)

  export {
    FirebaseProvider,
    FirebaseContext,
    useFirebase
  }