export enum METHODS {
    "POST" = "POST",
    "GET" = "GET",
    "PUT" = "PUT",
    "DELETE" = "DELETE"
}

export default class APIService {
    protected baseUrl
    protected gatewayKey
    protected apiKey

    constructor(that) {
        this.baseUrl = that.baseUrl
        this.gatewayKey = that.gatewayKey
        this.apiKey = that.apiKey
    }

    protected async api(
        url: string,
        method: METHODS,
        body?: object
    ) {
        try {
            console.log('API CALL')
            console.log({ ...this, url, method, body })

            const fullUrl = `${this.baseUrl}${url}`
            const response = await fetch(fullUrl, {
                method,
                headers:  {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Ocp-Apim-Subscription-Key": this.gatewayKey,
                    ...((this.apiKey !== '') && { 'x-api-key': this.apiKey })
                },
                ...(body && { body: JSON.stringify(body) })
            })
            const data = await response.json()

            return data
        } catch(err) {
            throw new Error(err)
        } 
    }
}