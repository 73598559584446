import { styled } from "@mui/material/styles"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from "@mui/material/IconButton"
import { useDirectory } from "../../contexts/DirectoryContext"

function DrawerHeader() {
    const { open, setOpen } = useDirectory()
    const handleDrawerOpen = () => setOpen(true)
    const handleDrawerClose = () => setOpen(false)
    
    const DH = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        height: 72
      }))

    return (<DH>
        <IconButton
            onClick={handleDrawerClose}
            sx={{...(!open && { display: 'none' })}}
        >
            <ChevronLeftIcon />
        </IconButton>
        <IconButton
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' })}}
        >
            <MenuIcon />
        </IconButton>
    </DH>)
}

export default DrawerHeader