import APIService, { METHODS } from "./APIService"
import AzureDBService, { DB_COLLECTIONS } from "./AzureDBService"
import { referencesInterface } from "../contexts/interfaces"
import env from '../util/env'

export enum CHAT_DIRECTION {
    'INCOMING' = "incoming",
    'OUTGOING' = "outgoing",
 }

export interface chatInterface {
    message: string,
    direction: CHAT_DIRECTION,
    position: "normal",
    type: "html",
    references?: Array<referencesInterface>
}

export default class ChatService extends APIService {
    private chatDb
    private pdfDb
    private auth

    constructor(firebase) {
        super({
            baseUrl: env.GATEWAY_URL,
            gatewayKey: env.GATEWAY_KEY,
            apiKey: env.PDF_AI_API_KEY
        })
    
        this.auth = firebase.auth.getAuth()
        this.chatDb = new AzureDBService(firebase, DB_COLLECTIONS.CHAT)
        this.pdfDb = new AzureDBService(firebase, DB_COLLECTIONS.PDF)
    }

    async analyze(id: string) {
        const pdfRes = await this.pdfDb.read(id)

        if(pdfRes.pdfAiRef) return pdfRes.pdfAiRef

        try {
            const uploadRes = await this.uploadUrlEP(
                pdfRes.storageRef,
                this.auth.currentUser.uid,
                pdfRes.filename
            )
            pdfRes.pdfAiRef = uploadRes.ref
            
            await this.pdfDb.update(id, pdfRes)
    
            const firstMessage = await this.getDefaultFirstMessage(
                pdfRes.filename,
                pdfRes.pdfAiRef
            )
            await this.saveChatFB(firstMessage, pdfRes.pdfAiRef)
    
            return pdfRes.pdfAiRef
        } catch(err) {
            console.error(err)
            throw new Error(err)
        }
    }

    async fetchChatsFB(pdfAiRef: string) {
        const data = await this.chatDb.query({ pdfAiRef })
        return data[0].chats
    }

    async saveChatFB(chatObj: chatInterface, pdfAiRef: string) {
        const data = await this.chatDb.query({ pdfAiRef })
        const chatData = data[0]

        if(chatData) {
            await this.chatDb.update(chatData._id, {
                pdfAiRef,
                chats: [
                ...(chatData?.chats ?? []),
                chatObj
            ]})
        } else {
            await this.chatDb.create({
                pdfAiRef,
                chats: [
                ...(chatData?.chats ?? []),
                chatObj
            ]})
        }
    }

    async getDefaultFirstMessage(
        pdfName: string,
        docId: string
    ) : Promise<chatInterface> {
        const chat = message => ({
            message,
            direction: CHAT_DIRECTION.INCOMING,
            position: 'normal' as any,
            type: 'html' as any
        })

        try {
            const initialPrompt = `Summarize this document in one paragraph. Start your response with "####Summary of ${pdfName}"`
            const response = await this.chatEP(docId, initialPrompt)

            const message = response.content

            if(!message) throw new Error('no message received')

            return chat(message)

        } catch(err) {
            console.error(err)
            return chat(`**${pdfName}** has been analyzed. How can I help you today?`)
        }
    }

    async chatEP(ref: string, message: string) {
        const body = {
            path: ref,
            question: message
        }

        const response = await fetch('https://pdf-ai-gateway.azure-api.net/ultimarii-rag/chat', {
            method: 'POST',
            headers:  {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Ocp-Apim-Subscription-Key": this.gatewayKey,
                ...((this.apiKey !== '') && { 'x-api-key': this.apiKey })
            },
            ...(body && { body: JSON.stringify(body) })
        })

        return {
            content: await response.text(),
            references: ''
        }
    }

    async uploadUrlEP(url: string, organization: string, doc_id: string) {
        const body = {
            organization, // organization and user specifier example 
            url, // storage url
            doc_id // id of the document from mongo
        }

        /**
         * 1) fetch the document from url
         * 2) process document into pkl file
         * 3) create embeddings from pkl file
         * 4) write embeddings to database
         * 5) return id/ref
         * 
         * steps 2, 3 4 = analysis
         * 
         * rag id
         */
        const response = await fetch('https://pdf-ai-gateway.azure-api.net/ultimarii-rag/upload', {
            method: 'POST',
            headers:  {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Ocp-Apim-Subscription-Key": this.gatewayKey,
                ...((this.apiKey !== '') && { 'x-api-key': this.apiKey })
            },
            ...(body && { body: JSON.stringify(body) })
        })

        const ref = await response.text()
        console.log('ref')
        console.log(ref)
        return {
            ref
        }
    }
}