import { useEffect } from "react"
import { CSSObject, styled } from "@mui/material/styles"
import  MuiDrawer from "@mui/material/Drawer"
import { Divider, List } from "@mui/material"

import DrawerHeader from "./DrawerHeader"
import { useTheme } from "@mui/material/styles"
import DrawerPlaceholder from "./DrawerPlaceholder"
import Profile from "./Profile"
import StartNewChat from "./StartNewChat"
import Directory from "./directory/Directory"
import { LOGIN_STATUS, useUser } from "../../contexts/UserContext/UserContext"
import { useDirectory } from "../../contexts/DirectoryContext"

function DrawerContainer() {
    const { open, setOpen } = useDirectory()
    const theme = useTheme()
    const { userState } = useUser()

    const drawerWidth = 350

    useEffect(() =>  {
        if(userState.loginStatus===LOGIN_STATUS.LOGGING_OUT)
            setOpen(false)
    }, [userState.loginStatus])

    const openedMixin = (): CSSObject => ({
        width: drawerWidth,
        overflowX: 'hidden',
    })
      
    const closedMixin = (): CSSObject => ({
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    })

    const sfp = { shouldForwardProp: prop => prop!=='open' }
    const Drawer = styled(MuiDrawer, sfp)(({ open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
        ...openedMixin(),
        '& .MuiDrawer-paper': openedMixin(),
    }),
        ...(!open && {
        ...closedMixin(),
        '& .MuiDrawer-paper': closedMixin(),
        })
    }))

    return(<><Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Divider />
        <List>
            <Directory />
            <StartNewChat />
        </List>
        <DrawerPlaceholder />
        <Profile />
    </Drawer></>)
}

export default DrawerContainer