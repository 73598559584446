import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { Fade, Dialog as MUIDialog }from "@mui/material"
import TextField from "@mui/material/TextField"
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { useUser, LOGIN_STATUS } from "../../contexts/UserContext/UserContext"
import { styled } from "@mui/material/styles"
import './fields.css'

function LoginDialog() {
    const {userState, login} = useUser()
    const handleSubmit = event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        login(data)
    }

    const open = [
        LOGIN_STATUS.LOGGED_OUT,
        LOGIN_STATUS.LOGGING_OUT
    ].some(status => status === userState.loginStatus)

    const Dialog = styled(MUIDialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            borderRadius: theme.spacing(2),
            background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,250,255,0.9) 100%)'
          },
    }))

    return (<Dialog open={open} fullWidth maxWidth='md' TransitionComponent={Fade}>
        <Box
            sx={{
                marginTop: theme => theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '67vh',
                paddingBottom: theme => theme.spacing(6),
            }}
        >
            <Box
                component="img"
                src="/logos/img-logo.png"
                sx={{
                    marginTop: 'auto',
                    width: '72px',
                    minWidth: '12.5%',
                    marginBottom: theme => theme.spacing(4)
                }}
            />
            <Typography
                component="h1"
                variant="h5"
                sx={{
                    fontWeight: 'bold',
                    color: theme => theme.palette.primary.dark
                }}
            >
                Sign in to Ultimarii
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                {/* TODO implement remember me */}
                {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                /> */}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        color: theme => theme.palette.primary.dark,
                        bgcolor: theme => theme.palette.secondary.light,
                        '&:hover': {
                            color: theme => theme.palette.primary.light
                        },
                        borderRadius: '8px',
                        fontWeight: 'bold',
                        marginTop: theme => theme.spacing(2)
                    }}
                >
                    Sign In
                </Button>
            </Box>
        </Box>
    </Dialog>)
}

export default LoginDialog