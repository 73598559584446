export enum FILE_STATUS {
    'CLEAR' = 'clear',
    'UPLOADING' = 'uploading',
    'ANALYZING' = 'analyzing',
    'LOADED' = 'loaded',
    'OTHER' = 'other',
}

export interface fileStateInterface {
    pdfDoc: pdfDocInterface,
    id: string,
    fileLocalUrl: string,
    fileStatus: FILE_STATUS
}

export interface directoryContentsInterface {
    pdfDoc: pdfDocInterface,
    id: string,
    selected: boolean
}

export interface pdfDocInterface {
    filename: string,
    pdfAiRef: string,
    storageRef: string,
    extension: string,
    mimetype: ''
    metadata?: {
        uploadDate: Date,
        size: number
    }
}

export interface referencesInterface {
    fromLine: number,
    pageNumber: number,
    toLine?: number
}