import { createContext, useContext, useReducer, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import { Alert, Button, Slide, Typography } from '@mui/material'

const AlertContext = createContext(null)
AlertContext.displayName = 'AlertContext'

const enum ALERT_STATUS {
  'SUCCESS',
  'ERROR',
  'INFO',
  'CLEAR'
}

interface stateInterface {
  alertText: string,
  alertStatus: ALERT_STATUS,
  undoHook: Function | null
}

interface dispatchParameterInterface {
  text?: string,
  type: ALERT_STATUS
  undoHook?: Function
}

interface alertContextValueInterface {
  success: (msg: string) => void,
  error: (msg: string) => void,
}

const alertReducer = (state, action) => {
  switch(action.type) {
    case ALERT_STATUS.SUCCESS:
      return {
        alertText: action.text,
        alertStatus: ALERT_STATUS.SUCCESS,
        undoHook: action.undoHook || null
      }

    case ALERT_STATUS.ERROR:
      return {
        alertText: action.text,
        alertStatus: ALERT_STATUS.ERROR,
        undoHook: null
      }

    default:
      return {
        alertText: '',
        alertStatus: ALERT_STATUS.CLEAR,
        undoHook: null
      }
  }
}

const AlertProvider = ({ children }) => {
  const initialState: stateInterface = {
    alertText: '',
    alertStatus: ALERT_STATUS.CLEAR,
    undoHook: null
  }

  const [state, dispatch] : [stateInterface, (ad: dispatchParameterInterface) => void]
    = useReducer(alertReducer, initialState)

  const handleClose = (event, reason?) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch({type: ALERT_STATUS.CLEAR})
  }

  const alertContextValue : alertContextValueInterface = {
    error: text => dispatch({
      text,
      type: ALERT_STATUS.ERROR
    }),
    success: text => dispatch({
      text,
      type: ALERT_STATUS.SUCCESS
    })
  }
  
  // todo implemenet this
  const undo = state.undoHook ? (
    <Button color="primary" size="small" onClick={e => {
      handleClose(e)
      state.undoHook()
    }}>
      <Typography sx={{
        color: theme => theme.palette.primary.contrastText,
        fontWeight: 'bold',
        fontSize: '13px'
      }}>
        UNDO
      </Typography>
    </Button>
  ): null

  return (
    <AlertContext.Provider value={alertContextValue}>
      {children}
      <Snackbar
        open={state.alertStatus!==ALERT_STATUS.CLEAR}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Slide direction="right" in={state.alertStatus!==ALERT_STATUS.CLEAR}>
          <Alert
            severity={
              state.alertStatus===ALERT_STATUS.ERROR ? 'error' : 'success'
            }
            sx={{
              maxWidth: '500px',
              minWidth: '70%'
            }}
            onClose={handleClose}
            action={undo}
          >
            {state.alertText} 
          </Alert>
        </Slide>
      </Snackbar>
    </AlertContext.Provider>
  )
}

const useAlert = () : alertContextValueInterface => useContext(AlertContext)
  
export {
  AlertProvider,
  AlertContext,
  ALERT_STATUS,
  useAlert
}