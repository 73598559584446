import PdfDrop from "./pdfDrop/PdfDrop"
import Grid from "@mui/material/Grid"
import ChatBoxContainer from "./chatPanel/ChatBoxContainer"
import FeedbackDialog from "./dialogs/Feedback"
import LoginDialog from "./dialogs/Login"
import Spinner from "./dialogs/Spinner"
import Box from "@mui/material/Box"
import DrawerContainer from "./drawer/DrawerContainer"

function Background() {
    return (<Box sx={{display: 'flex'}}>
    <FeedbackDialog />
    <LoginDialog />
    <Spinner />
    <DrawerContainer  />
    <Box component="main" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid item xs={6}> {/* PdfDrop */}
                <PdfDrop />    
            </Grid>

            <Grid item xs={6}> {/* ChatBox */}
                <ChatBoxContainer />
            </Grid>
        </Grid>
    </Box>
    </Box>)
}

export default Background