import { useAlert } from "../../../contexts/AlertContext"
import { useDirectory } from "../../../contexts/DirectoryContext"
import { useFile } from "../../../contexts/FileContext"
import { useFirebase } from "../../../contexts/FirebaseContext"
import AzureDBService, { DB_COLLECTIONS, UserDBService } from "../../../services/AzureDBService"
import CustomDropzoneUI from "./CustomDropzoneUI"
import { supportedUploadTypes } from "../../../util/filetypes"
import env, { checkEnv } from "../../../util/env"

function CustomDropzone({ setUploadProgress, SService }) {
    const alert = useAlert()
    const { beginUpload } = useFile()
    const { loadDirectory } = useDirectory()

    const firebase = useFirebase()
    const pdfDb = new AzureDBService(firebase, DB_COLLECTIONS.PDF)
    const userDb = new UserDBService(firebase)
        
    const uploadInProgressHandler = snapshot => {
        // make it load until 95% for the sake of user experience
        const progress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 95)
        setUploadProgress(progress)

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break

          case 'running':
            console.log('Upload is running')
            break
        }
    }

    const uploadFile = async (files) => { 
        // only supports one file at a time now, but using extensible infrastructure
        if(!files.length) {
            alert.error('Could not read file')
            return
        }

        // one file at a time for now
        const file = files[0]
        const filetypeIsSupported = supportedUploadTypes
            .map(({mimetype}) => mimetype)
            .every(type => type!==file.type)

        if(filetypeIsSupported) {
            const docxString = checkEnv.DOCX_UPLOAD_SUPPORT("enabled", ", DOCX")
            alert.error(`File types supported: PDF${docxString}`)
            return
        }
        if(file.size > 99999999) {
            alert.error('Max file size is 100MB')
            return
        }
        beginUpload()

        try {
            const { pathname, filename, mimetype } =
                await SService.uploadFileAzure(files[0])

            const { url } = await SService.getPdfAzure(pathname)

            const extension = supportedUploadTypes
                .find(({ mimetype: mt }) => mt === mimetype)
                .extension

            const pdfRef = await pdfDb.create({
                filename,
                    pdfAiRef: '',
                    storageRef: url,
                    mimetype,
                    extension,
                userId: userDb.getUID(),
                metadata: {
                    uploadDate: Date.now(),
                    size: file.size
                }
            })

            loadDirectory(pdfRef._id)
            alert.success('File upload successful')
        } catch(error) {
            console.error(error)
            alert.error('File upload error encountered')

            throw new Error(error)
        }
    }

    return (<CustomDropzoneUI setFiles={uploadFile} />)
}

export default CustomDropzone