import { User as UserType } from "firebase/auth"

export enum LOGIN_STATUS {
    'LOADING',
    'LOGGED_IN',
    'LOGGED_OUT',
    'LOGGING_IN',
    'LOGGING_OUT',
}

export interface stateInterface {
    user: UserType,
    loginStatus: LOGIN_STATUS
  }