import { List, ListItem, ListItemIcon, ListItemText, Avatar, Tooltip, Typography } from "@mui/material"
import LogoutIcon from '@mui/icons-material/Logout'
import AddCommentIcon from '@mui/icons-material/AddComment'
import { useState } from "react"
import { useUser } from "../../contexts/UserContext/UserContext"
import { useDirectory } from "../../contexts/DirectoryContext"
import ListItemButton from "./button/ListItemButton"
import { useFeedback } from "../../contexts/FeedbackContext"

function Profile() {
    const { ifOpen, open, setOpen } = useDirectory()
    const { userState, logout } = useUser()
    const { setShowFeedback } = useFeedback()
    const toggleOpen = () => setOpen(!open)
    
    const [feedbackHover, setFeedbackHover] = useState(false)
    const FEEDBACK =  feedbackHover ? 'Write feedback' : ''
    const feedbackTooltipTitle = ifOpen('',
        <Typography component="h1">{FEEDBACK}</Typography>
    )
    
    const [logoutHover, setLogoutHover] = useState(false)
    const LOGOUT =  logoutHover ? 'Logout' : ''
    const logoutTooltipTitle = ifOpen('',
        <Typography component="h1">{LOGOUT}</Typography>
    )

    return (<List sx={{marginTop: 'auto'}} disablePadding>
        <Tooltip title={feedbackTooltipTitle} placement="right" arrow>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => setShowFeedback(true)}
                    onMouseEnter={() => setFeedbackHover(true)}
                    onMouseLeave={() => setFeedbackHover(false)}
                    >
                    <ListItemIcon sx={{ mr: ifOpen(-1,'auto'), ml: 1 }}>
                        <AddCommentIcon />
                    </ListItemIcon>
                    <ListItemText primary={FEEDBACK} sx={{ opacity: ifOpen(1,0) }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
        <Tooltip title={logoutTooltipTitle} placement="right" arrow>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={logout}
                    onMouseEnter={() => setLogoutHover(true)}
                    onMouseLeave={() => setLogoutHover(false)}
                >
                    <ListItemIcon sx={{ mr: ifOpen(-1,'auto'), ml: 1 }}>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={LOGOUT} sx={{ opacity: ifOpen(1,0) }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
        <ListItem disablePadding>
            <ListItemButton onClick={toggleOpen}>
                <ListItemIcon sx={{ mr: ifOpen(0,'auto'), ml: -0.5 }}>
                    <Avatar sx={{
                        bgcolor: theme => theme.palette.secondary.dark,
                        padding: 'none'
                    }}>
                        {userState.user?.email?.charAt(0).toUpperCase()}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={userState.user?.email}
                    sx={{ opacity: ifOpen(1,0) }}
                />
            </ListItemButton>
        </ListItem>
    </List>)
}

export default Profile