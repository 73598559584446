import { BlobServiceClient } from "@azure/storage-blob"
import env from "../util/env"

export default class StorageService {
    private auth
    private blobServiceClient
    private containerClient

    constructor(firebase) {
        this.auth = firebase.auth.getAuth()

        this.blobServiceClient = new BlobServiceClient(
            `https://${env.STORAGE_ACCOUNT}.blob.core.windows.net/?${env.STORAGE_SAS_TOKEN}`
          )
        
        this.containerClient = this.blobServiceClient.getContainerClient(env.STORAGE_CONTAINER)
    }

    async uploadFileAzure(file) : Promise<{
        pathname: string,
        filename: string,
        mimetype: string,
    }> {
        const pathname = `${this.auth.currentUser.uid}/${encodeURIComponent(file.path)}`

        const blobClient = this.containerClient.getBlockBlobClient(pathname)
        await blobClient.uploadData(file)

        return { pathname, filename: file.path, mimetype: file.type }
    }

    async getPdfAzure(filename?): Promise<any> {
        try {
            return await this.containerClient.getBlockBlobClient(filename)
        } catch (e) {
            console.error("Error fetching blobs:", e)
            return null
        }
    }

    async createLocalUrl(
        remoteUrl: string,
        filename: string,
        type: string
    ) {
        const blob = await fetch(remoteUrl).then(r => r.blob())
        const file = new File([blob], filename, { type })
        return URL.createObjectURL(file)
    }

    async deletePdfAzure(filename?): Promise<any> {
        try {
            const blockBlobClient = await this.containerClient.getBlockBlobClient(filename)

            return await blockBlobClient.delete({
                deleteSnapshots: 'include'
            })

        } catch (e) {
            console.error("Error fetching blobs:", e)
            return null
        }
    }
}