import ListItem from "@mui/material/ListItem"
import { FILE_STATUS, useFile } from "../../contexts/FileContext"
import ListItemButton from "./button/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { AddCircle } from "@mui/icons-material"
import { useDirectory } from "../../contexts/DirectoryContext"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import { useAlert } from "../../contexts/AlertContext"
import CircularProgress from "@mui/material/CircularProgress"

function StartNewChat() {
    const {
        ifOpen,
        directoryState,
        setDirectoryState,
        chatSendEnabled
    } = useDirectory()
    const { fileState, uploadRef } = useFile()
    const alert = useAlert()

    const PDF_LIMIT = 5
    const startNewChat = () => {
        if(directoryState.length >= PDF_LIMIT) {
            alert.error(`Maximum ${PDF_LIMIT} files at a time supported.`)
        } else {
            
            if(fileState.fileStatus===FILE_STATUS.CLEAR)
                uploadRef.current?.click()

            else setDirectoryState(directoryState.map(content => {
                content.selected = false
                return content
            }, []))
        }
    }

    const enableButton = directoryState.length > 0
        &&  [FILE_STATUS.LOADED, FILE_STATUS.CLEAR]
            .some(status => status===fileState.fileStatus)
        
    const showButton = enableButton && chatSendEnabled
    
    const primary = enableButton
        ?   'Start new chat'
        :   ''

    const title = ifOpen('', <Typography component="h1">{primary}</Typography>)

    return (<><Tooltip title={title} placement="right" arrow>
        <ListItem
            sx={{ display: showButton ? 'block' : 'none' }}
        disablePadding>
            <ListItemButton onClick={startNewChat}> 
                <ListItemIcon sx={{ mr: ifOpen(-1,'auto'), ml: 0.5 }}>
                    {
                        enableButton
                        ?   <AddCircle />
                        :   <CircularProgress size={24} />
                    }
                </ListItemIcon>
                <ListItemText
                    primary={primary}
                    sx={{ opacity: ifOpen(1,0) }}
                />
            </ListItemButton>
        </ListItem>
    </Tooltip>
    </>)
}

export default StartNewChat