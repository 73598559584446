import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    Avatar as ChatAvatar,
  } from "@chatscope/chat-ui-kit-react"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import { FILE_STATUS, useFile } from "../../contexts/FileContext"
import { CHAT_DIRECTION, chatInterface } from "../../services/ChatService"
import Markdown from 'markdown-to-jsx'
import { renderToStaticMarkup } from 'react-dom/server'
import { useUser } from "../../contexts/UserContext/UserContext"
import Avatar from "@mui/material/Avatar"
import { useTheme } from "@mui/material/styles"
import { useEffect } from "react"
import { useDirectory } from "../../contexts/DirectoryContext"
import './chat.css'

// https://chatscope.io/storybook/react/

function ChatBox({chats, ask, msgListRef}) {
    const { chatSendEnabled } = useDirectory()
    const { fileState: { fileStatus }, setReferenceState } = useFile()
    const { userState: { user } } = useUser()
    const theme = useTheme()

    const chatDisabled = [
        FILE_STATUS.CLEAR,
        FILE_STATUS.UPLOADING
    ].some(status => status===fileStatus)

    const placeholder = chatDisabled
        ?   "Upload a document to start chat"
        :   "Start chat with Ultimarii..."

    const typingIndicator = (!chatSendEnabled && fileStatus === FILE_STATUS.LOADED)
        ?   <TypingIndicator content="Ultimarii is thinking..."/>
        :   null

    const fromUser = model => model.direction===CHAT_DIRECTION.OUTGOING

    const formatMessage = model => {
        if(fromUser(model))
            return `<span class="user-message">${model.message}</span>`

        const references = model?.references?.map((ref, i) => {
            return <button key={i} className="ref-button">
                p. {ref.pageNumber}
            </button>
        }) || []

        return renderToStaticMarkup(<>
            <Markdown>{model.message}</Markdown>
            {references}
        </>)
    }

    const messages = (chats as Array<chatInterface>).map((model, i) => {
        return <Message key={i} model={model}>
            <ChatAvatar style={{ display: fromUser(model) ? 'block' : 'none'}}>
                <Avatar sx={{ bgcolor: theme.palette.secondary.dark }}>
                    {user?.email?.charAt(0).toUpperCase()}
                </Avatar>
            </ChatAvatar>
            <Message.HtmlContent html={formatMessage(model)}/>
        </Message>
    })

    useEffect(() => {
        // userMessageBoxes
        document.querySelectorAll(
            '.cs-message--outgoing .cs-message__content-wrapper .cs-message__content'
        ).forEach(mb => {
            ;(mb as HTMLElement).style.backgroundColor = theme.palette.primary.dark
        })

        // aiMessageBoxes
        document.querySelectorAll(
            '.cs-message--incoming .cs-message__content-wrapper .cs-message__content'
        ).forEach(mb => {
            const style = (mb as HTMLElement).style
            style.backgroundColor = theme.palette.primary.light
            style.marginTop = theme.spacing(1)
            style.marginBottom = theme.spacing(2)
            style.marginLeft = theme.spacing(-2)
            style.paddingLeft = theme.spacing(4)
            style.paddingRight = theme.spacing(4)            
        })

        //refButtons
        document.querySelectorAll('.ref-button').forEach(rb => {
            const rbe = rb as HTMLElement
            rbe.style.backgroundColor = theme.palette.primary.main
            rbe.style.borderRadius = theme.spacing(2)
            rbe.style.padding = theme.spacing(1,2)
            rbe.style.margin = theme.spacing(0,1,1,0)

            rbe.onclick = e => {
                e.preventDefault()
                const pageNumber = (e.target as any)?.innerHTML?.substring(3) 

                if(pageNumber) setReferenceState({
                    pageNumber,
                    fromLine: -1 // customize when possible
                })
            }

            rbe.onmouseover = function(e) {
                e.preventDefault()
                rbe.style.backgroundColor = theme.palette.secondary.main
            }

            rbe.onmouseout = function(e) {
                e.preventDefault()
                rbe.style.backgroundColor = theme.palette.primary.main
            }
        })
    }, [chats])

    // https://chatscope.io/storybook/react/?path=/docs/components-message--docs#html-content
    return (<MainContainer style={{ border: 'none' }}>
        <ChatContainer>
            <MessageList
                typingIndicator={typingIndicator}
                ref={msgListRef}
            >
                {messages}
            </MessageList>
            <MessageInput
                placeholder={placeholder}
                attachButton={false}
                sendDisabled={!chatSendEnabled}
                disabled={chatDisabled}
                onSend={ask}
            />
        </ChatContainer>
    </MainContainer>)
}

export default ChatBox