import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useFile, FILE_STATUS } from "../../contexts/FileContext"
import CircularProgress from "@mui/material/CircularProgress"
import ExamplePrompt from "./ExamplePrompt"

function PlaceholderChatbox() {
    const { fileState: { fileStatus } } = useFile()
    const ANALYZING = [FILE_STATUS.ANALYZING, FILE_STATUS.OTHER]
        .some(status => fileStatus === status)

    const flexBoxOpts = (showCondition = true) => ({
        display: showCondition ? 'flex' : 'none',
        flexDirection: 'column',
        alignContent: 'center'
    })

    return (<Box
            sx={{
                width: '100%',
                height: '92.5%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                overflowY: 'scroll',
                display: (fileStatus!==FILE_STATUS.LOADED) ? 'block' : 'none'
            }}
        >
        <Box sx={{ ...flexBoxOpts(), padding: theme => theme.spacing(4) }}>
            <Box sx={{ m: 4 }} />
            <Box
                component="img"
                src="/logos/vert-logo.png"
                width="20%"
                sx={{
                    margin: 'auto'
                }}
            />
            <Box sx={{ m: 2 }} />
            <Box sx={flexBoxOpts(ANALYZING)}>
                <Typography
                        variant="h6"
                        sx={{
                            margin: 'auto',
                            fontWeight: 'bold',
                            color: theme => theme.palette.primary.dark,
                        }}
                    >
                        Ultimarii is analyzing...
                </Typography>
                <br />
                <br />
                <CircularProgress
                    size={50}
                    sx={{
                        color: theme => theme.palette.primary.light,
                        margin: 'auto'
                    }}
                    />

            </Box>
            <Box sx={flexBoxOpts(!ANALYZING)}>
                <Typography
                    variant="h6"
                    sx={{
                        margin: 'auto',
                        fontWeight: 'bold',
                        color: theme => theme.palette.primary.dark
                    }}
                >
                    Hey, User
                </Typography>
                <Typography variant="h6" sx={{
                    margin: 'auto',
                    fontWeight: 'bold',
                    color: theme => theme.palette.primary.main
                }}>
                    How can I help you today?
                </Typography>
                <Box sx={{ m: 4 }} />
                <Typography sx={{ fontSize: '12px' }}>
                    Here are some examples of prompts you can ask me:
                </Typography>
                <ExamplePrompt>
                    <strong>Show me recent regulatory filings</strong> for a critical mineral mine
                </ExamplePrompt>
                <ExamplePrompt>
                    <strong>Help me find</strong> all wind power projects in Western Canada over the last 10 years
                </ExamplePrompt>
                <ExamplePrompt>
                    <strong>Find Supplemental Information Requests</strong> for environmental impact assessments over the last 5 years
                </ExamplePrompt>
            </Box>
        </Box>
    </Box>)
}

export default PlaceholderChatbox