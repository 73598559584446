import { createContext, useContext, useState } from 'react'
import { useFirebase } from './FirebaseContext'
import { FILE_STATUS } from './interfaces'
import AzureDBService, { DB_COLLECTIONS } from '../services/AzureDBService'
import { useUser } from './UserContext/UserContext'
import { useDirectory } from './DirectoryContext'
import { useFile } from './FileContext'

const FeedbackContext = createContext(null)
FeedbackContext.displayName = 'FeedbackContext'

export enum SEVERITY {
    "BUG" = "BUG",
    "IMPROVEMENT" = "IMPROVEMENT",
    "GENERAL_FEEDBACK" = "GENERAL_FEEDBACK"
}

export enum USER_ROLE {
    "DEVELOPER" = "DEVELOPER",
    "PRODUCT_OWNER" = "PRODUCT_OWNER",
    "USER_TESTER" = "USER_TESTER",
    "OTHER" = "OTHER"
}

export interface feedbackInterface {
    details: string,
    severity: SEVERITY,
    userRole: USER_ROLE,
    appContext?: any,
    bugDetails?: {
        steps: string,
        videoLink: string
    },
    date?: any,
}

export interface feedbackValuesInterface {
    showFeedback: boolean,
    setShowFeedback: (e: boolean) => void,
    sendFeedback: (f: feedbackInterface) => any
}

const FeedbackProvider = ({ children }) => {
    const firebase = useFirebase()
    const { userState } = useUser()
    const { directoryState } = useDirectory()
    const { fileState, referenceState } = useFile()
    const feedbackDb = new AzureDBService(firebase, DB_COLLECTIONS.FEEDBACK)

    const [ showFeedback, setShowFeedback ] = useState(false)
    
    const sendFeedback = ({
        details,
        bugDetails = null,
        severity,
        userRole
    } : feedbackInterface) : Promise<any> => {

        return feedbackDb.create({
            details,
            bugDetails,
            severity,
            userRole,
            date: new Date(),
            context: {
                username: userState.user.email,
                directoryState,
                fileState,
                referenceState
            }
        })
    }

    const feedbackValues : feedbackValuesInterface = {
        showFeedback,
        setShowFeedback,
        sendFeedback
    }

    return (
        <FeedbackContext.Provider value={feedbackValues}>
        {children}
    </FeedbackContext.Provider>
    )
}

const useFeedback = () : feedbackValuesInterface => useContext(FeedbackContext)
  
export {
    FeedbackProvider,
    FeedbackContext,
    FILE_STATUS,
    useFeedback
}