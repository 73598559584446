import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { Fade, IconButton, Dialog as MUIDialog, MenuItem, Select }from "@mui/material"
import TextField from "@mui/material/TextField"
import Typography from '@mui/material/Typography'
import { styled } from "@mui/material/styles"
import './fields.css'
import { SEVERITY, USER_ROLE, feedbackInterface, useFeedback } from "../../contexts/FeedbackContext"
import { useState } from "react"
import CloseIcon from '@mui/icons-material/Close'
import { useAlert } from "../../contexts/AlertContext"

function FeedbackDialog() {
    const { success } = useAlert()
    const { showFeedback, setShowFeedback, sendFeedback } = useFeedback()

    const [ feedbackType, setFeedbackType ] = useState(SEVERITY.GENERAL_FEEDBACK)

    const handleSubmit = event => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const feedback : feedbackInterface = {
            details: data.get('details') as string,
            severity: data.get('feedback-type') as SEVERITY,
            userRole: USER_ROLE.DEVELOPER
        }

        const bugDetails = {
            steps: data.get('steps') as string,
            videoLink: data.get('video-link') as string
        }

        if(bugDetails.steps) feedback.bugDetails = bugDetails

        sendFeedback(feedback).then(() => {
            success("Feedback submitted") 
            setShowFeedback(false)
        })
    }

    const Dialog = styled(MUIDialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            borderRadius: theme.spacing(2),
            background: 'white'
          },
    }))

    const isBug = feedbackType===SEVERITY.BUG

    return (<Dialog
            open={showFeedback}
            fullWidth
            maxWidth='md'
            TransitionComponent={Fade}
        >
        <Box
            sx={{
                marginTop: theme => theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: isBug ? '95vh' : '60vh',
                paddingBottom: theme => theme.spacing(6),
            }}
        >
            <IconButton
                aria-label="close"
                onClick={() => setShowFeedback(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500]
                }}
                >
                <CloseIcon />
            </IconButton>
            <Typography
                component="h1"
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    color: theme => theme.palette.primary.dark,
                    marginTop: 'auto',
                    marginBottom: theme => theme.spacing(2)
                }}
            >
                Feedback Form
            </Typography>
            <Typography variant="body1">Thank you for helping improve Ultimarii.</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="feedback-type"
                    name="feedback-type"
                    label="Feedback type"
                    value={feedbackType}
                    onChange={ e => setFeedbackType(e.target.value as SEVERITY)}
                    fullWidth
                >
                    <MenuItem value={SEVERITY.BUG}>Bug Report</MenuItem>
                    <MenuItem value={SEVERITY.IMPROVEMENT}>Feature Improvement</MenuItem>
                    <MenuItem value={SEVERITY.GENERAL_FEEDBACK}>General Feedback</MenuItem>
                </Select>
                <TextField
                    margin="normal"
                    fullWidth
                    id="details"
                    label="Details"
                    name="details"
                    rows={4}
                    multiline
                />
                { feedbackType===SEVERITY.BUG && <><TextField
                    margin="normal"
                    fullWidth
                    id="steps"
                    label="Environment, and steps to reproduce this bug"
                    name="steps"
                    rows={2}
                    multiline
                /> <TextField
                    margin="normal"
                    fullWidth
                    id="video-link"
                    label="Link to video?"
                    name="video-link"
                /></> }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        color: theme => theme.palette.primary.dark,
                        bgcolor: theme => theme.palette.secondary.light,
                        '&:hover': {
                            color: theme => theme.palette.primary.light
                        },
                        borderRadius: '8px',
                        fontWeight: 'bold',
                        marginTop: theme => theme.spacing(2)
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    </Dialog>)
}

export default FeedbackDialog