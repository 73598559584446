import { Box, Slide } from '@mui/material'
import WebViewer from '@pdftron/pdfjs-express'
import { useEffect, useRef } from 'react'
import { useFile } from '../../../contexts/FileContext'
import { useTheme } from "@mui/material"
import env from "../../../util/env"

function PdfPreview({shouldDisplay}) {
    const { fileState, referenceState } = useFile()
    const viewer = useRef(null)
    const instanceRef = useRef(null)
    
    const theme = useTheme()

    useEffect(() => {
        console.log('key')
        console.log(env.PDFTRON_API_KEY)
        WebViewer({
            path: '/pdfjs-express-viewer',
            initialDoc: fileState.fileLocalUrl,
            extension: fileState.pdfDoc.extension,
            licenseKey: env.PDFTRON_API_KEY
        },
        viewer.current
    ).then(instance => {
        instanceRef.current = instance
        instance.UI.setFitMode(instance.UI.FitMode.FitWidth)

        instance.UI.disableElements(['ribbons'])
        instance.UI.disableElements(['toolsHeader'])

        instance.Core.documentViewer.addEventListener('documentLoaded', () => {

            const iframeDoc = instance.UI.iframeWindow.document
            const header : any = iframeDoc.querySelector('[data-element="header"]')
            header.style.background = theme.palette.secondary.light
            header.style.border = 'none'

            const zoomInButton : any = iframeDoc.querySelector('[data-element="zoomInButton"]')
            const zoomOutButton : any = iframeDoc.querySelector('[data-element="zoomOutButton"]')
            zoomInButton.style.background = theme.palette.secondary.light
            zoomOutButton.style.background = theme.palette.secondary.light

            const documentContainer : any = iframeDoc.querySelector('[data-element="documentContainer"]')
            documentContainer.style.background = theme.palette.secondary.light

        })

        }).catch(e => {
            
        })
    }, [fileState])

    useEffect(() => {
        const pageNumber : number = Number(referenceState?.pageNumber)
        const documentViewer = instanceRef?.current?.Core?.documentViewer

        if(pageNumber && documentViewer){
            if(pageNumber <= documentViewer.getPageCount()) {
                documentViewer.scrollTo(pageNumber, 0)
                documentViewer.setCurrentPage(pageNumber)
            }
          }
    }, [referenceState])
    
    return (<Box sx={{ backgroundColor: theme => theme.palette.primary.light }}>
        {shouldDisplay && <Slide in={shouldDisplay} unmountOnExit>
            <Box>
                <div
                    className="pdfjs-express-viewer"
                    ref={viewer}
                    style={{ width: '100%', height: 'calc(100vh - 72px)' }}
                ></div>
            </Box>
        </Slide>}
    </Box>)
}

export default PdfPreview