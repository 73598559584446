const pe = process.env

interface envInterface {
    GATEWAY_URL,
    GATEWAY_KEY,
    GATEWAY_DB_URL,
    PDF_AI_API_KEY,
    PDFTRON_API_KEY,
    DOCX_UPLOAD_SUPPORT,
    STORAGE_ACCOUNT,
    STORAGE_CONTAINER,
    STORAGE_SAS_TOKEN,
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID
}

const env : envInterface = {
    GATEWAY_URL: pe.REACT_APP_GATEWAY_URL,
    GATEWAY_KEY: pe.REACT_APP_GATEWAY_KEY,
    GATEWAY_DB_URL: pe.REACT_APP_GATEWAY_DB_URL,
    
    PDF_AI_API_KEY: pe.REACT_APP_PDF_AI_API_KEY,
    PDFTRON_API_KEY: pe.REACT_APP_PDFTRON_API_KEY,
    DOCX_UPLOAD_SUPPORT: pe.REACT_APP_DOCX_UPLOAD_SUPPORT,
    
    STORAGE_ACCOUNT: pe.REACT_APP_STORAGE_ACCOUNT,
    STORAGE_CONTAINER: pe.REACT_APP_STORAGE_CONTAINER,
    STORAGE_SAS_TOKEN: pe.REACT_APP_STORAGE_SAS_TOKEN,
    
    // to deprecate
    REACT_APP_API_KEY: pe.REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN: pe.REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID: pe.REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET: pe.REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDER_ID: pe.REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID: pe.REACT_APP_APP_ID,
}

/*
    if environment variable value (varVal) is "false"
        then return second argument (if 2nd arg undefined, then return '')
    else return first argument
*/
const mappedEnv = Object.entries(env).map(({ 0: varName, 0: varVal }) => {
    const checker = (condition : string, trueCase, falseCase = '') => {
        return varVal===condition ? trueCase : falseCase
    }
    return [ varName, checker ]
})

export const checkEnv : envInterface = Object.fromEntries(mappedEnv)

export default env