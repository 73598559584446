import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone'
import Fade from "@mui/material/Fade"

function PdfProgress({progress}) {
    const uploadInProgress = progress > 0 && progress < 95

    return (<Fade in={uploadInProgress} unmountOnExit>
        <Box component="div" sx={{
            display: uploadInProgress ? 'flex' : 'none',
            flexDirection: 'column',
        }}>
            <UploadFileTwoToneIcon sx={{
                margin: 'auto',
                fontSize: '72px',
                color: theme => theme.palette.primary.main
            }} />
            <br />
            <br />
            <LinearProgress variant="determinate" value={progress}
                sx={{ width: '50%', margin: 'auto' }} />
            <Typography variant="h6" sx={{
                margin: 'auto',
                paddingTop: theme => theme.spacing(2),
                fontWeight: 'bold',
                color: theme => theme.palette.primary.dark
            }}>
                PDF upload in progress...
            </Typography>
        </Box>
    </Fade>)
}

export default PdfProgress