import { checkEnv } from "./env"

export const supportedUploadTypes = [
    {
        extension: 'pdf',
        mimetype: 'application/pdf'
    },
    
    ...checkEnv.DOCX_UPLOAD_SUPPORT("enabled", [{
        extension: 'docx',
        mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }], [])
]