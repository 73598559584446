import APIService, { METHODS } from "./APIService"
import env from "../util/env"

export enum DB_COLLECTIONS {
    "USER" = 'users',
    "PDF" = 'pdfs',
    "CHAT" = 'chats',
    "FEEDBACK" = 'feedback',
    "SPECIAL" = "SPECIAL"
}

export default class AzureDBService extends APIService {
    protected auth
    protected collection

    constructor(firebase: any, collection: DB_COLLECTIONS) {
        super({
            baseUrl: env.GATEWAY_DB_URL,
            gatewayKey: env.GATEWAY_KEY,
            apiKey: ''
        })
        
        this.auth = firebase.auth.getAuth()
        this.collection = collection
    }

    async create(
        contents: any,
        collection : DB_COLLECTIONS = this.collection
    ) {
        return await this.api(
            collection,
            METHODS.POST,
            contents
        )
    }

    async read(
        id: string,
        collection : DB_COLLECTIONS = this.collection
    ) {
        return await this.api(
            `${collection}/${id}`,
            METHODS.GET
        )
    }

    async query(
        query: object,
        collection : DB_COLLECTIONS = this.collection
    ) {
        const json = JSON.parse(JSON.stringify(query))
        const q = new URLSearchParams(json).toString()
        
        return await this.api(
            `${collection}?${q}`,
            METHODS.GET
        )
    }

    async update(
        id: string,
        contents: object,
        collection : DB_COLLECTIONS = this.collection
    ) {
        return await this.api(
            `${collection}/${id}`,
            METHODS.PUT,
            contents
        )
    }

    async delete(
        id: string,
        collection : DB_COLLECTIONS = this.collection
    ) {
        return await this.api(
            `${collection}/${id}`,
            METHODS.DELETE
        )
    }
}

export class UserDBService {
    private auth

    constructor(firebase) {
        this.auth = firebase.auth.getAuth()
    }

    userIsLoggedIn() : boolean {
        return !!this?.auth?.currentUser?.uid
    }

    getUID() : string {
        return this.auth.currentUser.uid
    }
}