import Paper from "@mui/material/Paper"
import { useState } from "react"
import PdfProgress from "./stages/PdfProgress"
import PdfPreview from "./stages/PdfPreview"
import CustomDropzone from "./stages/CustomDropzone"
import { useFirebase } from "../../contexts/FirebaseContext"
import StorageService from "../../services/StorageService"
import { FILE_STATUS, useFile } from "../../contexts/FileContext"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"

function PdfDrop() {
    const { fileState } = useFile()

    const SService = new StorageService(useFirebase())
    const [uploadProgress, setUploadProgress] = useState(0)

    const shouldDisplayPdfPreview = [
        FILE_STATUS.LOADED,
        FILE_STATUS.ANALYZING
    ].some(status => fileState.fileStatus === status)

    return (<Paper
        sx={{
            minHeight: '100vh',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme => theme.spacing(0, 0),
            margin: theme => theme.spacing(0, 0)
        }}
        elevation={4}
    >
            <Box sx={{
                height: '72px',
                display: shouldDisplayPdfPreview ? 'flex' : 'none',
                justifyContent: 'space-between',
                alightContent: 'center',
                flexDirection: 'row',
            }}>
            <Box
                component="img"
                src="/logos/horz-logo.png"
                height='48px'
                sx={{
                    margin: theme => theme.spacing(2),
                    marginRight: 'auto'
                }}
            />
            <Divider />
            </Box>
        {/* before upload */}
        { fileState.fileStatus===FILE_STATUS.CLEAR && <CustomDropzone
            setUploadProgress={setUploadProgress}
            SService={SService}
        /> }

        {/* during upload */}
        <PdfProgress progress={uploadProgress} />

        {/* after upload */}
        <PdfPreview shouldDisplay={shouldDisplayPdfPreview} />
    </Paper>)
}

export default PdfDrop