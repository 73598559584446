import { createContext, useContext, useEffect, useState } from 'react'
import { useFirebase } from '../FirebaseContext'
import { useAlert } from '../AlertContext'
import { LOGIN_STATUS, stateInterface } from './interfaces'
import loginCtrl from './loginCtrl'
import logoutCtrl from './logoutCtrl'

const UserContext = createContext(null)
UserContext.displayName = 'UserContext'

interface userContextValuesInterface {
  userState: stateInterface,
  login: (d: FormData) => void,
  logout: () => void,
  confirmLogin: () => void
  confirmLogout: ()=> void
}

const UserProvider = ({ children }) => {
  const alert = useAlert()
  const firebaseAuth = useFirebase().auth

  const [state, setState] = useState<stateInterface>({
    user: null,
    loginStatus: LOGIN_STATUS.LOADING
  })

  const userContextValues : userContextValuesInterface = {
    userState: state,
    login: data => loginCtrl(data, setState, firebaseAuth, alert),
    logout: () => logoutCtrl(setState, firebaseAuth, alert),
    confirmLogin: () => setState({
      user: state.user,
      loginStatus: LOGIN_STATUS.LOGGED_IN
    }),
    confirmLogout: () => setState({
      user: null,
      loginStatus: LOGIN_STATUS.LOGGED_OUT
    })
  }
    
  // reauth
  useEffect(() => {
    firebaseAuth.onAuthStateChanged(firebaseAuth.getAuth(), user => {
      if (user) {
        setState({
          user,
          loginStatus: LOGIN_STATUS.LOGGING_IN
        })
  
      } else userContextValues.confirmLogout()
    })
  }, [])
  
  return (
    <UserContext.Provider value={userContextValues}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () : userContextValuesInterface => useContext(UserContext)

export {
  UserProvider,
  UserContext,
  LOGIN_STATUS,
  useUser
}